import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import { sponsors } from '../util'

const BlogDetails = () => {
    const params = useParams();
    const [ fetching, setFetching ] = useState();
    const [ details, setDetails ] = useState();

    const getPostDetails = async (slug) => {
        try {
            setFetching(true)
            const { data: details } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/wp-json/wp/v2/posts?slug=${slug}&_embed`)
            setDetails(details?.[0] || {})
        }
        catch(err) {

        }
        finally {
            setFetching(false)
        }
    }

    useEffect(() => {
        if(params?.slug) getPostDetails(params?.slug)
    }, [params?.slug])

    return (
        <PageContainer processing={fetching}>
            <section id="overview" className="parallax-section sponsors">
                <div className="container">
                    <div className="row">
                        <div className="wow fadeInUp col-12 f-16 mb-2" data-wow-delay="0.6s">
                            <img src={details?._embedded?.['wp:featuredmedia']?.['0']?.source_url || ''} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                        </div>
                        <div className="wow fadeInUp col-12 f-16 mb-2" data-wow-delay="0.6s">
                            <h3 className='text-center'><b>{details?.title?.rendered || ''}</b></h3>
                            <div className='text-justify' dangerouslySetInnerHTML={{ __html: details?.content?.rendered || '' }} />
                        </div>
                    </div>
                   
                </div>
            </section>

        </PageContainer>

    )
}

export default BlogDetails
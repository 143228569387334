import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import appRoutes from '../../routes/appRoutes';



export default function Logo({ disabledLink = false, sx, width = 'auto', height = 'auto' }) {
    const theme = useTheme();

    const logo = (
        <Box sx={{ width: width, height: height, ...sx }}>
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="" style={{ width: "100%", height: '100%', objectFit: 'contain', margin: 'auto' }} />
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to={appRoutes.root}>{logo}</RouterLink>;
}

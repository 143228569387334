import { Card, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Step, StepLabel, Stepper, TextField } from '@mui/material'
import axios from 'axios'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import emailjs from '@emailjs/browser';
import Autocomplete from "react-google-autocomplete";
import { useSnackbar } from 'notistack'

const steps = ['Step 1', "Step 2", "Step 3"];

const Evaluation = () => {
    const params = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const [ isSubmitting, setIsSubmitting ] = useState();
    const [ selectedAddress, setSelectedAddress ] = useState("");
    const [ activeStep, setActiveStep ] = useState(0);
    const [ checkTerms, setCheckTerms ] = useState(false);


    const defaultValue = {
        address: '', voucher: '', report: '', planning: '', situation: '', phone: '', name: '', email: '', propertyRelationship: '',
        bedroom_double: 0,
        bedroom_single: 0,
        bathrooms: 0,
        other_rooms: '',
        dining: '',
        kitchen: '',
        extension: '',
        free_standing: 0,
        off_street_parking: 0,
        garaging: '',
        carport: '',
        roof: '',
        exterior: '',
        views: '',
        section_contour: '',
        features: '',
        moreInfo: ''
    }

    const SelectInput = ({ multipleSelect = false, name, setFieldValue, options = [], value }) => (
        <Grid container className='custom-select' spacing={3}>
            {
                options?.map((option, key) => (
                    <Grid key={key} item lg={3} md={4} sm={6} xs={12}>
                        <span className={((value == option) || value?.includes(option)) ? 'active' : '' }
                            onClick={() => {
                                if(multipleSelect) {
                                    let values = [ ...value ];
                                    const newValues = (values?.includes(option)) ? values?.filter(op => op != option) : [ ...values, option ];
                                    setFieldValue(name, newValues)

                                } else {
                                    setFieldValue(name, option)
                                }
                            }}
                        >
                            {option}
                        </span>
                    </Grid>
                ))
            }
        </Grid>
    )

    const NumberInput = ({ name, setFieldValue, value, type = "number", label }) => (
        <div container className='custom-number-input'>
            <span>{label}</span>
            <input value={value} onChange={({ target }) => setFieldValue(name, target.value)} name={name} id={name} type={type} />
        </div>
    )

    const checkAndNext = async (values) => {
        switch(activeStep) {
            case 0:
                if(values.address && values.voucher && values.report && values.planning && values.situation && values.phone && values.name && values.email && values.propertyRelationship) {        
                    window.scrollTo(0, 0);
                    return setActiveStep(activeStep + 1);
                } else {
                    return alert('All fields in this section is required');
                }
            case 2:
                return submit(values);
            default:
                window.scrollTo(0, 0);
                return setActiveStep(activeStep + 1);
        }
    }

    const getErrorMessage = (value) => {
        switch(value) {
            case "Tenant":
                return 'Sorry this report is only for Property Owners';
            case "Buyer":
                return 'Sorry this report is only for Property Owners';
            case "Not my Property":
                return 'Sorry this report is only for Property Owners';
            case "Real Estate Agent":
                return <>Please email your query to <a href='mailto:admin@aucklandhouseprices.co.nz'>admin@aucklandhouseprices.co.nz</a></>;
            default: 
                return '';
        }
    }

    const defaultMoreInfo = {
        bedroom_double: 0,
        bedroom_single: 0,
        bathrooms: 0,
        other_rooms: '',
        dining: '',
        kitchen: '',
        extension: '',
        free_standing: 0,
        off_street_parking: 0,
        garaging: '',
        carport: '',
        roof: '',
        exterior: '',
        views: '',
        section_contour: '',
        features: ''
    }

    const submit = async (values) => {
        try {
            setIsSubmitting(true)
            const val = values?.moreInfo == "Skip" ? { ...values, ...defaultMoreInfo } : values;
            await emailjs.send(process.env.REACT_APP_SERVICE_KEY, 'template_o5c80mh', val, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                setActiveStep(3)
            }, (error) => {            
                enqueueSnackbar(error?.text || 'An error occurred', { variant: 'error' })
            })
            .catch((error) => {
                enqueueSnackbar(error?.text || error?.message || 'An error occurred', { variant: 'error' })
                setIsSubmitting(false)
            })
            .finally(() => setIsSubmitting(false));
        }
        catch(error) {
            enqueueSnackbar(error?.text || error?.message || 'An error occurred', { variant: 'error' })
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        if(location?.state && typeof(location?.state) == 'string') {
            setSelectedAddress(location?.state)
        }
    }, [location])

    return (
        <PageContainer>
            <section id="overview" className="parallax-section sponsors">
                <div className="container">
                    {
                        activeStep !== 3 &&
                        <div className="">
                            <h3 className='text-center mb-3' style={{ fontWeight: 800 }}>
                                <i>Your property is unique. Your appraisal will be too.</i>
                            </h3>

                            <Stepper alternativeLabel activeStep={activeStep}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    }
                    <div className="form">

                        <Formik
                            initialValues={{ ...defaultValue, address: selectedAddress  }} enableReinitialize
                            onSubmit={submit}
                        >
                            {
                                ({ handleChange, setFieldValue, values }) => (
                                    <Form>
                                        {
                                            activeStep == 0 &&
                                            <>
                                                <div className="col-12 mb-2">
                                                    <Card className='p-3'>
                                                        <label className='text-primary'>Home Address *</label>
                                                        <Autocomplete
                                                            id="address" required className='auto-search'
                                                            placeholder="Enter Address"
                                                            value={values.address}
                                                            apiKey={process.env.REACT_APP_GOOGLE_KEY}
                                                            onPlaceSelected={(place) => {
                                                                setFieldValue("address", place.formatted_address);
                                                                setSelectedAddress(place.formatted_address)
                                                            }}
                                                            onChange={handleChange}
                                                            libraries={["places"]}
                                                            // types={["(regions)"]}
                                                            options={{
                                                                types: "address",
                                                                componentRestrictions: { country: "nz" },
                                                            }}
                                                        />       
                                                    </Card>    
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <Card className='p-3'>
                                                        <label className='text-primary mb-2'>Do you want to claim your marketing discount voucher up to $1000</label>  
                                                        <SelectInput options={["Claim now", "Later"]} setFieldValue={setFieldValue} name="voucher" value={values.voucher} />
                                                    </Card>    
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <Card className='p-3'>
                                                        <label className='text-primary'>Choose Report *</label>
                                                        <SelectInput options={["Property Valuation Report", "Rental Appraisal", "Both"]} setFieldValue={setFieldValue} name="report" value={values.report} />      
                                                    </Card>    
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <Card className='p-3'>
                                                        <label className='text-primary'>When are you planning to sell? *</label>
                                                        <SelectInput options={["Right Away", "Within 3 - 6 months", "Within 6 - 9 months", "Within the next year", "Not Sure"]} setFieldValue={setFieldValue} name="planning" value={values.planning} />  
                                                    </Card>    
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <Card className='p-3'>
                                                        <label className='text-primary'>What describes your situation right now? *</label>
                                                        <SelectInput options={["Looking to Refinancing", "Downsizing", "Upsizing", "Buy then Sell", "Selling Investment", "Thinking to Moving", "Others"]} setFieldValue={setFieldValue} name="situation" value={values.situation} />  
                                                    </Card>    
                                                </div>
                                                <div className="col-12 mb-2">
                                                    <Card className='p-3'>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} md={6}>
                                                                <label className='text-primary'>Full Name *</label>
                                                                <TextField fullWidth className='form-field' onChange={handleChange} value={values.name} required name="name" id="name" placeholder="Enter full name" />        
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <label className='text-primary'>Mobile Number *</label>
                                                                <TextField fullWidth className='form-field' type="number" onChange={handleChange} value={values.phone} required name="phone" id="phone" placeholder="Enter mobile number" />        
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <label className='text-primary'>Email Address *</label>
                                                                <TextField fullWidth className='form-field' onChange={handleChange} required name="email" value={values.email} id="email" placeholder="Enter email address" />        
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <label className='text-primary'>What's your relationship with <span style={{ color: '#000' }}>{values.address}</span> (select only one)</label>  
                                                                <small className='text-error'>{getErrorMessage(values.propertyRelationship)}</small>
                                                                <SelectInput options={["Property Owner", "Tenant", "Part of the Estate", "Buyer", "Not my Property", "Real Estate Agent"]} setFieldValue={setFieldValue} name="propertyRelationship" value={values.propertyRelationship} />  
                                                            </Grid>
                                                        </Grid>
                                                    </Card>    
                                                </div>
                                            </>
                                        }
                                        {
                                            activeStep == 1 &&
                                            <>
                                                <div className="col-12 mb-2">
                                                    <Card className='p-3'>
                                                        <label className='text-primary'>Do you want to tell us more about your property? (Optional)</label>
                                                        <SelectInput options={["Proceed", "Skip"]} setFieldValue={setFieldValue} name="moreInfo" value={values.moreInfo} />  
                                                    </Card>    
                                                </div>
                                                
                                                {
                                                    values.moreInfo == 'Proceed' &&
                                                    <>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>How many Bedrooms & Bathrooms</label>
                                                                <Grid container className='pt-2' spacing={3}>
                                                                    <Grid item xs={12} md={6} lg={3}>
                                                                        <NumberInput name="bedroom_double" label="Double" setFieldValue={setFieldValue} value={values.bedroom_double} />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} lg={3}>
                                                                        <NumberInput name="bedroom_single" label="Single" setFieldValue={setFieldValue} value={values.bedroom_single} />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} lg={3}>
                                                                        <NumberInput name="bathrooms" label="Bathrooms" setFieldValue={setFieldValue} value={values.bathrooms} />
                                                                    </Grid>
                                                                </Grid>    
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary mb-2'>Other rooms (select as many as apply)</label>  
                                                                <SelectInput options={["Family Room", "Dining Room", "Rumpus", "Sep Lounge", "Sunroom", "Study/Office", "Attic", "Conservatory", "Basement", "Separate Laundry", "Sleepout", "Workshop"]} setFieldValue={setFieldValue} name="other_rooms" multipleSelect value={values.other_rooms} />
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Dining</label>
                                                                <SelectInput options={["Open Plan", "Combined Lounge", "Combined Kitchen", "Separate"]} setFieldValue={setFieldValue} name="dining" value={values.dining} />      
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Kitchen</label>
                                                                <SelectInput options={["Designer", "Modern", "Standard", "Gas Cooktop", "Gas Oven", "Reticulated", "Gas appliances bottled", "Butlers Pantry"]} setFieldValue={setFieldValue} name="kitchen" value={values.kitchen} />  
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Extension/Renovation - Have there been any extensions since the house was first built?</label>
                                                                <p className='mt-05 mb-05'>This includes any structural changes, decks, garages, sleepouts along with any kitchen or bathroom updates and renovations</p>
                                                                <SelectInput options={["Yes", "No", "I don't know"]} setFieldValue={setFieldValue} name="extension" value={values.extension} />  
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Garaging (select as many as apply)</label>
                                                                <SelectInput options={["Single", "Double", "Tandem", "More than 2", "Internal access", "Lockup"]} setFieldValue={setFieldValue} name="garaging" value={values.garaging} multipleSelect />
                                                                <Divider className='mt-2' />
                                                                <Grid container className='pt-2' spacing={3}>
                                                                    <Grid item xs={12} md={6} lg={3}>
                                                                        <NumberInput name="free_standing" label="Free Standing" setFieldValue={setFieldValue} value={values.free_standing} />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6} lg={3}>
                                                                        <NumberInput name="off_street_parking" label="Off street parking" setFieldValue={setFieldValue} value={values.off_street_parking} />
                                                                    </Grid>
                                                                </Grid>  
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Carport</label>
                                                                <SelectInput options={["Single", "Double", "Tandem", "More than 2", "Free Standing Access"]} setFieldValue={setFieldValue} name="carport" value={values.carport} />
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Roof</label>
                                                                <SelectInput options={["Iron", "Concrete Tile", "Stone Chip", "Metal Tile", "Decramastic", "Long Run", "Colorsteel", "Shingle", "Slate Tile", "Butynol"]} setFieldValue={setFieldValue} name="roof" value={values.roof} />
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Exterior</label>
                                                                <SelectInput 
                                                                    options={[
                                                                        "Weatherboard", "Composite Cement", "Fiber Cement", "Hardiplank", "Harditex", "Cedar", "Brick", "Insulclad", "Monolithic Cladding", "Plaster",
                                                                        "Fibrolite", "Concrete Block", "Masonry Block/Brick", "Weatherside", "Stucco", "Linea", "Roughcast", "Concrete", "Corrugated Iron", "Board/Batten"
                                                                    ]} 
                                                                    setFieldValue={setFieldValue} name="exterior" value={values.exterior} />
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Views (select as many as apply)</label>
                                                                <SelectInput multipleSelect options={["City", "Harbour/Sea", "Bush", "Local", "Wide", "Rural", "Urban", "Park", "Private"]} setFieldValue={setFieldValue} name="views" value={values.views} />
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Section Contour</label>
                                                                <SelectInput options={["Level", "Sloping", "Steep", "Mixed"]} setFieldValue={setFieldValue} name="section_contour" value={values.section_contour} />
                                                            </Card>    
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <Card className='p-3'>
                                                                <label className='text-primary'>Features (select as many as apply)</label>
                                                                <SelectInput multipleSelect options={["Swimming Pool", "Spa", "Polished Floors", "Decking", "Walk-in Wardrobe", "Tennis Court", "Pantry", "Gas Fire"]} setFieldValue={setFieldValue} name="features" value={values.features} />
                                                            </Card>    
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                        {
                                            activeStep == 2 &&
                                            <>
                                                <div className="col-12 mb-2 mt-2">
                                                    <FormControlLabel 
                                                        checked={checkTerms}
                                                        onChange={({ target }) => setCheckTerms(target.checked) } 
                                                        control={<Checkbox />} label={<p className='f-15'>I consent to a phone call confirming that my request has been received and to also receive emails containing real estate related information from <a className='text-primary' href='https://aucklandhouseprices.co.nz'>aucklandhouseprices.co.nz</a>. I understand that I may unsubscribe at any time.</p>} />

                                                </div>
                                                <p className='f-15'>Click here for our <a className='text-primary' target={'_blank'} href='/Website_privacy_policy.pdf'>Terms of use and privacy policy</a></p>
                                            </>
                                        }
                                        {
                                            activeStep == 3 &&
                                            <div className='success-section'>
                                                <h3 className='text-center text-secondary mb-0 mt-0' style={{ fontWeight: 800, fontSize: '32px', color: '#18304b' }}>Thank You!</h3>
                                                <p className='f-15 text-center'>You are now one step closer to knowing your property value in today's market, expect a phone call from us to confirm that we have received your request.</p>
                                                <button className='custom-btn mt-3' type='button' onClick={() => history.push('/')} disabled={isSubmitting}>
                                                    Home
                                                </button>
                                            </div>
                                        }

                                        { activeStep !== 3 &&
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={6} container justifyContent={'flex-start'}>
                                                    { activeStep !== 0 && <button className='custom-btn btn-2 mt-3' type='button' onClick={() => setActiveStep(activeStep - 1)} disabled={isSubmitting}>
                                                        Previous
                                                    </button> }
                                                </Grid>
                                                <Grid item xs={6} container justifyContent={'flex-end'}>
                                                    {
                                                        (
                                                            (activeStep == 0 && (values.propertyRelationship == "Property Owner" || values.propertyRelationship == "Part of the Estate")) ||
                                                            (activeStep == 1) || ((activeStep == 2) && checkTerms)
                                                        ) &&
                                                        <button className='custom-btn mt-3' type='button' onClick={() => checkAndNext(values)} disabled={isSubmitting}>
                                                           { isSubmitting ? 'Submitting...' : activeStep == 2 ? 'Submit' : 'Next' }
                                                        </button>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                    </Form>
                                )
                            }

                        </Formik>
                    </div>
                   
                </div>
            </section>

        </PageContainer>

    )
}

export default Evaluation
// export const regLink = "https://whova.com/portal/registration/ific_202211/";
export const regLink = "https://forms.gle/x6Ue7XhdkQtr6xhY9";

export const sponsors = [
    { img: "logo.png", title: 'Central Bank of Nigeria' },
    { img: "Bankers-Committee.jpg", title: 'Bankers Committee' },
    { img: "NAICOM.jpg", title: 'National Insurance Commission' },
    { img: "NCC.jpg", title: 'Nigerian Communications Commission' },
    { img: "sec_logo.jpg", title: 'Security and Exchange Commission Nigeria' },
    { img: "NDIC.jpg", title: 'Nigeria Deposit Insurance Corporation' },
    { img: "Pencom.jpg", title: 'National Pension Commission' },
];

export const hotels = [
    { 
        img: "transcorp hilton.jpg",
        hotel: "Transcorp Hilton Hotel",
        address: "1 Aguiyi Ironsi St, Maitama 900001, Abuja",
        website: "https://www.hilton.com/hilton/abuja",
        email: "hilton.abuja@hilton.com",
        phoneNumbers: "+234 803 901 3000",
        contactPerson: "Chukwudi Amadi (08030656700)",
        roomTypes: "Hilton Guest Room/N115,000.00 per night, Deluxe Room/N130,000.00 per night"
    },
    { 
        img: "Rock-View-Hotel.jpeg",
        hotel: "Rockview Hotel Abuja",
        address: "Plot 194 Adetokunbo Ademola Cres, Wuse 2 904101, Abuja",
        website: "http://www.rockviewhotelsltd.com",
        email: "reservationsclassic@rockviewhotelsltd.com",
        phoneNumbers: "094614600, 08099901127-28",
        contactPerson: "Anna Osho (08035904027)",
        roomTypes: "Diamond Room/N50,000.00 per night"
    },
    { 
        img: "summerset-continental.jpg",
        hotel: "Summerset Hotel",
        address: "34 Usuma street off Ghana street, Maitama Abuja",
        website: "https://summersetcontinentalhotel.com/",
        email: "usuma@summersetcontinentalhotel.com",
        phoneNumbers: "+234 704 008 4444",
        contactPerson: "Gloria (+2349058218938) +234 813 543 4505",
        roomTypes: "Deluxe Room/N65,000.00 per night, Executive Room/N80,000.00 per night, Suit/N120,000.00 per night"
    },
    { 
        img: "four-palms.jpeg",
        hotel: "Four Palms Residence",
        address: "38 Libreville Crescent, Wuse 2 900288, Abuja",
        website: "www.fourpalmsresidence.com",
        email: "info@fourpalmsresidence.com",
        phoneNumbers: "+234 913 563 4046",
        contactPerson: "Anna",
        roomTypes: "Deluxe Room/N90,000.00 per night, Ankara Room/N130,000.00 per night, Luxury Suits/N130,000.00 per night"
    },
    { 
        img: "Fraser-Suites.jpeg",
        hotel: "Fraser Suites",
        address: "Plot number 294 Leventis Close, Central Business District, Abuja, Nigeria",
        website: "www.abuja.frasershospitality.com",
        email: "reservations.abuja@frasershospitality.com",
        phoneNumbers: "+234 8139888888",
        contactPerson: "Janet Osakwe (+234807987162)",
        roomTypes: "Studio Deluxe Apartment/N150,000.00 per night, 1 Bedroom Apartment/N220,000.00 per night"
    },
];

export const videos = [
    { url: "Home page Video.mp4", title: 'IFIC', type: 'video/mp4' },
    { url: "NIGERIA-AT-THE-AFI-GLOBAL-POLICY-FORUM-2022-(Dead-Sea,-Jordan)-(1080p).mp4", title: 'Nigeria at the AFI Global Policy Forum 2022 (Dead Sea, Jordan)', type: 'video/mp4' },
    { url: "eNaira-One-Year-Anniversary-Highlight.mp4", title: 'eNaira One Year Anniversary Highlight', type: 'video/mp4' },
];

export const artifacts = [
    { 
        url: "NFIS1.pdf",
        image: 'national-financial-inclusion-strategy.png', 
        title: "National Financial Inclusion Strategy (NFIS 3.0)",
        desc: <div>
            <b>National Financial Inclusion Strategy (NFIS 3.0)</b> spearheads the country's drive towards achieving the Financial Inclusion target of 95% by 2024. 
            The Strategy is focused on:
            <ul>
                <li>Increase adoption and usage of financial services in priority demographics</li>
                <li>Ensure robust enabling financial services infrastructure</li>
                <li>Expand Digital Financial Services and platforms</li>
                <li>Improve financial inclusion coordination, capacity, and governance</li>
            </ul>
        </div>
    },
    { 
        url: "NFS final CORRECTION.pdf",
        image: 'national-fintech-strategy.jpg', 
        title: "",
        desc: <div>
            <b>National FinTech Strategy</b> provides demand and supply side recommendations to drive the uptake and usage of Fintechs in Nigeria. The Strategy outlines two strategic objectives: ecosystem and governance, as well as three priorities namely;
            <ul>
                <li>Deploy principle-based rules, regulations, and infrastructure foundational to building a thriving fintech ecosystem.</li>
                <li>Build a fintech ecosystem that supports acceleration, engagement, and collaboration.</li>
                <li>Create a multi-disciplinary talent pool and employability opportunities across the fintech ecosystem.  </li>
            </ul>
        </div>
    },
    { 
        url: "National Strategy Leveraging Agent Networks for Womens Financial Inclusion_Final_Nov 2022.pdf",
        image: 'strategy-leveraging-agent-network.png', 
        title: "",
        desc: <div>
            <b>Strategy for Leveraging Agent Networks to drive women's Financial Inclusion</b> is an offshoot of the Framework for advancing women's Financial Inclusion. It provides stakeholders with ways to optimize agent networks to increase women’s Financial Inclusion in Nigeria.
            The Strategy is a fulfillment of the Strategic Imperative 3 under the Framework which advocates for the expansion of delivery channels to reach women customers closer to home to close the Financial Inclusion Gender Gap in Nigeria.
            The Strategy seeks to:
            <ul>
                <li>To 'at least' double the number of female financial services agents between 2022 and 2024.</li>
                <li>To increase the ratio of female to male financial services agents from 15:85 to 35:65 by 2024.</li>
                <li>To promote the innovation and deployment of at least 10 gender-centric financial products or interventions that actively leverage agent networks.</li>
                <li>To ensure that stakeholders pursue geographically tailored solutions.</li>
            </ul>
        </div>
    },
    { 
        url: "PSMD vision 2025 EDITED FINAL.pdf",
        image: 'payment-system-vision.png', 
        title: "",
        desc: <div>
            <b>Payment System Vision 2025</b> builds on the legacy and achievement of the PSV 2020 which provided a roadmap for the reform of the Nigerian payments system. 
            The revised document will focus the attention of critical stakeholders on contemporary developments that will drive digital innovations and payment in the future, such as contactless payments, big data, open, banking, among others.
            The PSV 2025 will enhance Nigerian payments system and ensure that it is widely utilised domestically, supports government's financial inclusion objectives and meets international standards whilst contributing to overall national economic growth and development of Nigeria.
        </div>
    },
    // { 
    //     url: "",
    //     image: 'nigeria-financial-service-map.jpg', 
    //     title: "",
    //     desc: <div>
    //         <b>Nigeria Financial Services Map (NFS Map)</b> is an online real-time geospatial database on financial services in Nigeria for Regulators, Government Agencies, Financial Services Providers (FSPs) and the public. 
    //         It is an interactive tool leverages Geospatial Information Systems (GIS) to improve the measurement and tracking of financial access points. The NFSMaps aids analytics on users (merchants, agents, bank branches) and financial inclusion insights (transaction channel success &amp; failure rates, volume, and value of transactions). 
    //     </div>
    // },
];

export const materials = [
    { url: "IFIC'22 Agenda_Annotated Agenda_Flow.pdf", title: 'Agenda', type: 'pdf' }
];

export const cbn_executives = [
    { 
        img: "CBN-Gov-CON.jpg", name: 'Godwin Emefiele, CON', position: 'Governor, Central Bank of Nigeria',
        details: <>
            <h4 className="pt-2 mb-1 text-left">Mr. Godwin Emefiele, Governor, Central Bank of Nigeria</h4>
            Godwin Emefiele is the Governor of the Central Bank of Nigeria (CBN). Prior to his joining the CBN, he spent over 26 years in commercial banking culminating in his tenure as Group Managing Director and Chief Executive Officer of Zenith Bank PLC, one of Nigeria’s largest banks with over 7,000 staff, about US$3.2 billion in shareholders’ funds, and subsidiaries in Ghana, Sierra Leone, Gambia, South Africa, China, and the United Kingdom.
            <br />
            Before his banking career, he was a lecturer in Finance and Insurance in two Nigerian Universities. Mr. Emefiele holds degrees in Banking and Finance from the University of Nigeria, Nsukka, and is also an alumnus of Stanford University, Harvard and Wharton Graduate Schools of Business where he took courses in Negotiation, Service Excellence, Critical Thinking, Leading Change and Strategy.
        </>
    },
    { 
        img: "Mrs-Aishah-Ahmad.jpg", name: 'Mrs Aishah Ahmad', position: 'Deputy Governor, Financial System Stability, Central Bank of Nigeria',
        details: <>
            <h4 className="pt-2 mb-1 text-left">Mrs Aishah Ahmad, Deputy Governor, Financial System Stability, Central Bank of Nigeria</h4>
            Mrs. Aishah Ahmad was appointed Deputy Governor at the Central Bank of Nigeria (CBN) on the 23rd March 2018. As Deputy Governor in charge of Financial System Stability, she is responsible for leading the effort to promote a sound financial system in Nigeria; one of the principal objects of the Bank as specified within the CBN Act. In this role, Aishah is a member of the Governing Board and Committee of Governors at the CBN and Chairman, Financial Institutions Training Centre (FITC).
            <br />
            Prior to her appointment at the CBN, Aishah was Executive Director, Retail Banking at Diamond Bank Plc. Her banking and investment career spans several financial institutions including NAL Bank Plc, Zenith Bank Plc and Stanbic IBTC Bank Plc (a member of Standard Bank Group, SA) amongst others. 
            <br />
            Aishah is a member of the Chartered Financial Analyst (CFA) Institute and Chartered Alternative Investment Analyst (CAIA) Association. She also holds an M.Sc. in Finance and Management from the Cranfield School of Management, UK, and an MBA (Finance) from the University of Lagos, Nigeria. She obtained her B.Sc. in Accounting from the University of Abuja, Nigeria.
   
        </>
    },
    { 
        img: "DG-Corporate-Services.jpg", name: 'Adamu Edward', position: 'Deputy Governor, Corporate Services, CBN',
        details: <>
            <h4 className="pt-2 mb-1 text-left">Mr. Edward L. Adamu, Deputy Governor, Corporate Services, Central Bank of Nigeria</h4>
            Mr. Edward Lametek Adamu was appointed Deputy Governor of the Central Bank of Nigeria on 23rd March 2018. Prior to this, he served as the CBN Director of Human Resources from 2016 to 2018.
            <br />
            In the course of his career in the Central Bank, he was seconded to the Gombe State Civil Service from 2008 to 2010 as the Senior Special Assistant and Head, Procurement Management and Due Process Office. He returned to the CBN in 2010 and was appointed Director of Strategy Management Department in 2012. 
            <br />
            Edward graduated from the Ahmadu Bello University, Zaria in 1981 where he obtained a Bachelor of Science (Bsc.) Honours degree in Quantity Surveying with Second Class Upper Division. He also obtained Post-graduate certifications in Project & Programme Management, Strategy Management, Credit Administration, Knowledge Management and Human Resource Management. He is a fellow of the Nigerian Institute of Quantity Surveyors and the Institute of Credit Administration

        </>
    },
    { 
        img: "Folashodun-Adebisi-Shonubi.jpg", name: 'Mr. Folasodun Adebisi Shonubi', position: 'Deputy Governor, Operations, Central Bank of Nigeria',
        details: <>
            <h4 className="pt-2 mb-1 text-left">Mr. Folasodun Adebisi Shonubi, Deputy Governor, Operations, Central Bank of Nigeria</h4>

            Mr. Folashodun Adebisi Shonubi holds Masters degrees in Business Administration and Mechanical Engineering,from University of Lagos. He is a resourceful banker with over 30 years professional experience.
            <br />
            Prior to his appointment as Deputy Governor in the Central Bank of Nigeria, he was the Managing Director/CEO in the Nigeria Inter-Bank Settlement System PLC (from 2012 – 2018), Executive Director, Information Technology and Operations at Union Bank of Nigeria Plc; a member of the Board of Union Homes and Director, Information Technology and Corporate Services in Renaissance Securities Nigeria limited, with responsibility for the Group' s IT infrastructure in Africa.
            <br />
            Between 1999 and 2007, he worked in MBC International as Deputy General Manager and supervised their IT operational platforms, served in First City Monument Bank Limited as Vice President and in Ecobank Nigeria Limited as Executive Director. Mr. Shonubi also had a stint with Citibank Nigeria Limited as its Head, Treasury Operations (1990-1993).
            <br />
            He has served on a number of sub-committees of the Bankers'Committee, including the Ethics and Professionalism sub-committee.
        </>
    },
    { 
        img: "Kingsley-Obiora.png", name: 'Dr. Kingsley Obiora', position: 'Deputy Governor, Economic Policy, Central Bank of Nigeria',
        details: <>
            <h4 className="pt-2 mb-1 text-left">Dr. Kingsley Obiora, Deputy Governor, Economic Policy, Central Bank of Nigeria</h4>
            Kingsley Obiora resumed as a Deputy Governor in the Central Bank of Nigeria on 2nd March 2020. Prior to this, he was an Alternate Executive Director in the International Monetary Fund (IMF) in Washington DC, USA. In this capacity, he was a member of the Executive Board, collectively responsible for conducting the daily operations of the IMF. He also assisted to represent the interests of 23 African Countries, including Nigeria, at the Board.
            <br />
            He first joined the IMF through the globally-competitive “Economist Program” in 2007 and worked in both the European Department as well as the Strategy, Policy and Review Department, whilst carrying out extensive work on exchange rate assessment, debt sustainability analyses, decoupling and spillovers, real sector analyses, as well as several reviews of Financial Sector Assessments. Prior to this, Dr. Obiora worked at the West African Monetary Institute in Accra, Ghana and the Centre for Econometric and Allied Research at the University of Ibadan.
            <br />
            While on secondment from the IMF, Dr. Obiora simultaneously served as Technical Adviser to the Nigeria's National Economic Management Team as well as Special Assistant to the President Jonathan’s Chief Economic Adviser from October 2011 to May 2014. From June 2014 until July 2018, Dr. Obiora served as Special Adviser on Economic Matters to the Governor of the Central Bank of Nigeria (CBN). 
            <br />
            Dr. Obiora has a first degree from the University of Benin as well as Masters and Doctorate degrees in Economics from the University of Ibadan.

        </>
    },
    { 
        img: "Director-DFD.jpeg", name: 'Mr. Philip Yila Yusuf', position: 'Director, Development Finance Department, CBN',
        details: <>
            <h4 className="pt-2 mb-1 text-left">Mr. Yusuf Yila, Director, Development Finance Department, CBN</h4>
            Philip Yila Yusuf is Director, Development Finance Department at the Central Bank of Nigeria (CBN). In this role he is responsible for stimulating credit to the real sector. Prior to this appointment, he was the pioneer Managing Director of NIRSAL Microfinance Bank. Philip joined the CBN in June 2011 after a long stint at Oando Plc, leaving as General Manager Operations with responsibility for oil trading, supply chain, facilities engineering, terminalling, customer service and manufacturing.
            <br />
            Philip holds a Bachelor of Engineering degree in Electronics and Electrical Engineering from the Federal University of Technology, Owerri and a Master of Science (M.Sc.) in Global Banking and Finance from the prestigious Kings College, London. He is an Alumnus of the respected Harvard Business School’s having attended the General Management Programme (GMP24).
            <br />
            Philip presently serves on the Board of many development institutions, such as Bank of Industry (BOI), Nigerian Export Import Bank (NEXIM), Bank of Agriculture (BOA), Nigeria Incentive Based Risk Sharing System for Agricultural Lending (NIRSAL) Plc, and Agricultural Credit Guarantee Scheme Fund (ACGSF). He also represents the CBN on the Board of Nigeria Agricultural Insurance Corporation (NAIC), Nigeria Electricity Supply Industry – Stabilization Strategy Limited (NESI-SSL), Critical Infrastructure Investment Fund (CIIF), and the recently revamped Nigeria Commodity Exchange (NCX).

        </>

    },
];

export const speakers = [
    { 
        img: "queen-maxima.jpeg", name: 'H.M. Queen Máxima of the Netherlands', position: "United Nations Secretary-General's Special Advocate for Inclusive Finance for Development",
    },
    { 
        img: "Mrs-Aishah-Ahmad.jpg", name: 'Mrs Aishah Ahmad', position: 'Deputy Governor, Financial System Stability, CBN',
    },
    { 
        img: "Folashodun-Adebisi-Shonubi.jpg", name: 'Folashodun Shonubi', position: 'Deputy Governor Operations, Central Bank of Nigeria',
    },
    { 
        img: "Mrs.-Elsie-Addo-Awadzi.png", name: 'Mrs. Elsie Addo Awadzi', position: 'Deputy Governor, Bank of Ghana',
    },
    { 
        img: "Sheila Mmbijiewe.jpg", name: 'Sheila Mmbijiewe', position: 'Deputy Governor, Central Bank of Kenya',
    },    { 
        img: "Farida.jpeg", name: 'Farida Tahir', position: 'Chief Executive Officer, Grassroot Microfinance Bank',
    },
    { 
        img: "Herbert Wigwe.jpg", name: 'Herbert Wigwe', position: 'Chief Executive Officer, Access Holding Company',
    },
    { 
        img: "Abubakar-Suleiman.jpeg", name: 'Abubakar Suleiman', position: 'Chief Executive Officer Sterling Bank PLC',
    },
    { 
        img: "8 Saurbah Garg.png", name: 'Saurbah Garg', position: 'Chief Executive Officer, Unique Identification Authority of India (UIDAI)',
    },
    { 
        img: "9 Inez Murray.jpg", name: 'Inez Murray', position: 'Chief Executive Officer, Financial Alliance for Women',
    },
    { 
        img: "Tosin Eniolorunda.jpeg", name: 'Mr. Tosin Eniolorunda', position: 'Chief Executive Officer, TeamApt',
    },
    { 
        img: "Premier-Oiwoh.jpg", name: 'Mr. Premier Oiwoh', position: 'MD/CEO, Nigeria Inter-Bank Settlement System',
    },
    { 
        img: "16 Tayo Oviosu.jpg", name: 'Tayo Oviosu', position: 'Founder & Chief Executive Officer of Paga',
    },
    { 
        img: "Umar Shuaib Ahmed.jpeg", name: 'Umar Shuaib Ahmed', position: 'Executive Director, Zenith Bank Plc',
    },
    { 
        img: "UZOMA DOZIE.jpg", name: 'Uzoma Dozie', position: 'Chief Executive Officer, Sparkle Microfinance Bank',
    },
    { 
        img: "17 Isaiah headshot.jpeg", name: 'Isaiah Owolabi', position: 'Chief Executive Officer, Enhancing Financial Inclusion & Access',
    },
    { 
        img: "Rakiya Mohammed.jpeg", name: 'Rakiya S. Mohammed', position: 'Director, Information Technology Department, Central Bank of Nigeria',
    },
    { 
        img: "Director-DFD-1.jpeg", name: 'Mr. Philip Yila Yusuf', position: 'Director, Development Finance Department, CBN',
    },
    { 
        img: "13 Jimoh Musa Itopa DPSMD.jpeg", name: 'Jimoh Musa Itopa', position: 'Director Payment System Management Department',
    },
    { 
        img: "Mr. Ebenezer Onyeagwu.jpeg", name: 'Mr. Ebenezer Onyeagwu', position: 'Managing Director, Zenith Bank Plc.',
    },
    { 
        img: "14 Mrs. Monguno, D,CPD.jpg", name: 'Mrs. Rashida Jumoke Monguno', position: 'Director, Consumer Protection Department',
    },
    { 
        img: "Ibrahim.jpeg", name: 'Prof. Isa Ali Ibrahim', position: 'Federal Minister of Communications and Digital Economy of Nigeria',
    },
    { 
        img: "FCT-MINISTER-797x1024.jpg", name: 'Alhaji Mohammed Musa Bello CON', position: 'Honorable Minister, Federal Capital Territory',
    },
    { 
        img: "Dr-Alfred-Hannig.jpg", name: 'Dr. Alfred Hannig', position: 'Executive Director, Alliance for Financial Inclusion (AFI)',
    },
    { 
        img: "Chad_Blackman.jpg", name: 'H. E. Chad Blackman', position: 'Senior Advisor to the Director-General of the International Labour Organization (ILO)',
    },
    { 
        img: "annnagincherman.jpg", name: 'Anna Gincherman', position: 'CEO, ConsumerCentrix',
    },
    { 
        img: "Anna.jpeg", name: 'Anna Wallace', position: 'Senior Program Officer for Regulatory and Consumer Protection Technologies, Bill and Melinda Gates Foundation',
    },
    { 
        img: "10 Ayman Hussein.png", name: 'Ayman Hussein', position: 'First Sub-Governor Information Technology, Central Bank of Egypt ',
    },
    { 
        img: "Professor-Olayinka.jpeg", name: 'Professor Olayinka David-West', position: 'Associate Dean, Lagos Business School',
    },
    { 
        img: "16 Henrietta Bankole  Olusina.jpg", name: 'Henrietta Bankole Olusina', position: 'Vice President and Practice Lead of Economic Inclusion at Rockefeller Philanthropy Advisors',
    },
    { 
        img: "18 Ms Carolyn Folami.jpeg", name: 'Ms Carolyn Folami', position: 'Head, Business Development, Nigeria Identity Management Commission',
    },
    { 
        img: "19 Obisan Portrait.jpg", name: 'Dayo Obisan', position: 'Executive Commissioner (Operations) Securities and Exchange Commission',
    },
    { 
        img: "Ronke-Kuye.jpg", name: 'Ronke Kuye', position: 'Chief Executive Officer Shared Agent Network Expansion Facilities (SANEF) Ltd',
    },
    { 
        img: "Dr. Paul Oluikpe.jpeg", name: 'Dr. Paul Oluikpe', position: 'Head, Financial Inclusion Delivery Unit, CBN',
    },
    { 
        img: "Tolulope-Babajide.jpeg", name: 'Tolulope Babajide', position: 'Gender Network Manager, Financial Sector Deepening Africa, Nairobi',
    },
    { 
        img: "ific2022_logo.png", name: 'Olugbenga Agboola', position: 'Founder & Chief Executive Officer, Flutterwave',
    },
    { 
        img: "Ndubisi Anyanwu.jpg", name: 'Ndubuisi Anyanwu', position: 'Country Director, Mercy Corps Nigeria',
    },
    { 
        img: "Tomilola Majekodunmi.jpg", name: 'Ms. Tomilola Majekodunmi', position: 'Co-Founder & Chief Executive Officer, Bankly',
    },
    { 
        img: "ific2022_logo.png", name: 'Ms. Olasimbo Akinrosotu', position: 'Youth Officer, Federal Ministry of Youth and Sports Development',
    },
    { 
        img: "Dr. Rasha Negm.jpg", name: 'Ms. Rasha Negm', position: 'Assistant Sub-Governor, Fintech & Innovation, Central Bank of Egypt',
    },
    { 
        img: "Nneka Onyeali Ikpe.jpg", name: 'Ms. Nneka Onyeali-Ikpe', position: 'Managing Director, Fidelity Bank Plc.',
    },
    { 
        img: "Thomas Olorundare.png", name: 'Mr. Thomas Olorundare', position: 'Commissioner of Insurance, Nigeria Insurance Commission.',
    },
    { 
        img: "Bello Hassan.jpeg", name: 'Bello Hassan', position: 'Managing Director, Nigeria Deposit Insurance Company',
    },
    { 
        img: "Innocent Isichei.jpeg", name: 'Innocent Isichei', position: 'Chief Policy Adviser, Paystack Payment Limited',
    },
    { 
        img: "ific2022_logo.png", name: 'Henrietta Bankole-Olusina', position: 'Vice President, Economic Inclusion/ Nigeria Programme Director, Rockefeller Philanthropy Advisors',
    },
    { 
        img: "Gail Makenete.jpg", name: 'Gail Makenete', position: 'Chair, Expert Leaders Group, Graca Machel Trust',
    },
    { 
        img: "ific2022_logo.png", name: 'Bunmi Lawson', position: 'Managing Director/Chief Executive Officer, EDFIN Microfinance Bank Limited',
    },
    { 
        img: "Bashir Alhassan Nuhu.jpg", name: 'B. A. Nuhu', position: 'Director, Communication & Public Affairs, Nigeria Deposit Insurance Company',
    },
    { 
        img: "Abisola Onigbogi.jpg", name: 'Abisola Onigbogi', position: 'Executive Director, ARM Pensions',
    },
    { 
        img: "Tanuj Bhojwani.jpg", name: 'Mr. Tanuj Bhojwani', position: 'Board Member, Digital Public Goods Alliance',
    },
    { 
        img: "ific2022_logo.png", name: 'Evelyne Kanini Kilonzo', position: 'Senior Manager and Portfolio Lead of the Financial Inclusion and Innovation, Central Bank of Kenya',
    },
];

import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation, useParams, } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import $ from 'jquery'
import appRoutes from '../routes/appRoutes'
import { useMediaQuery } from 'react-responsive';
import { Form, Formik } from 'formik'
import axios from 'axios'
import { usePlacesWidget } from "react-google-autocomplete";
import emailjs from '@emailjs/browser';
import { useSnackbar } from 'notistack'


const Home = () => {
    const md = useMediaQuery({ query: '(min-width: 1200px)' })
    const minMd = useMediaQuery({ query: '(min-width: 787px)' })
    const sm = useMediaQuery({ query: '(max-width: 767px)' })
    const xs = useMediaQuery({ query: '(max-width: 400px)' })
    const history = useHistory()
    const params = useParams()
    const [ success, setSuccess ] = useState(false);
    const [ posts, setPosts ] = useState([]);
    const [ fetching, setFetching ] = useState();
    const [ address, setAddress ] = useState("");
    const [ sentMail, setSentMail ] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_KEY,
        onPlaceSelected: (place) => {
          setAddress(place?.formatted_address || "");
        },
        libraries: ["places"],
        options: {
            types: "address",
            componentRestrictions: { country: "nz" },
        },
    });


    const init = async () => {
        try {
            setFetching(true)
            const { data:  posts } = await axios.get('https://cms.aucklandhouseprices.co.nz/wp-json/wp/v2/posts?_embed');
            setPosts(posts);
        }
        catch(err) {

        }
        finally {
            setFetching(false)
        }
    }

    useEffect(() => { 
        init()
    },[]);

    const faqs = [
        { 
            title: "When will I receive my Comparative Market Analysis Report",
            desc: "We will contact you straight away to confirm that we have received your request (usually the same day). We then work on your report as soon as we receive your consent. The assessment is typically undertaken remotely and takes under three hours, with the report e-mailed to you as soon as it's completed. If you wish to get a more accurate price and discuss an effective marketing strategy, Auckland House Prices will assign a vetted local licensed agent to get in touch with you to discuss the report and arrange a time for a physical inspection of the property."
        },
        { 
            title: "How do I claim my $1000 marketing voucher?",
            desc: "You will be eligible to claim up to a $1000 marketing voucher if you wish to deal with our recommended agent to list your property. The voucher is valid for six months from the date of issue, with a reduction of $100 per month for the following six months."
        },
        { 
            title: "Are there additional costs for the report?",
            desc: "The Comparative Market Analysis Report is free, with no additional or hidden charges for a physical inspection. All report recommendations are made in good faith, using the best available knowledge and information. No liability for any losses incurred, whether directly or indirectly, will be accepted by anyone involved in the report, including the agent and www.aucklandhouseprices.co.nz"
        },
        { 
            title: "I am considering using another agent, can I still expect the same price range as in the report?",
            desc: "No. Our reports and price guidance are based on our agents' expertise and local knowledge, as well as their ability to target the right buyers for your property with proven marketing strategies. Other agents will not have these strengths, so the price you get will likely be a lot lower."
        },
    ]

    const contactForm = async (values, { resetForm }) => {
        try {
            await emailjs.send(process.env.REACT_APP_SERVICE_KEY, 'template_d8zh2wf', values, process.env.REACT_APP_PUBLIC_KEY)
            setSentMail({
                status: 'success',
                message: 'We have received your mail and will get back to you shortly. Thank you!'
            });
            resetForm()
        }
        catch(error) {
            setSentMail({
                status: 'error',
                message: error?.text || error?.message || 'An error occurred'
            })
        }
    }

    useEffect(() => {
        const path = history.location.pathname.split('/')[1]
        if(path) {
            if(document.getElementById(path)) {
                $('html, body').animate({
                    scrollTop: $(`#${path}`).offset().top - 75
                }, 1000)
            } else {
                history.push(appRoutes.page404)
            }
        }
    }, [params])

    return (
        <PageContainer pageTitle='Home' processing={fetching}>
            <section id="intro" className="parallax-section" 
                style={{ 
                    justifyContent: 'flex-end', flexDirection: "column", alignItems: 'center', 
                    background: `none`,
                    // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/new_family_bg.jpg)`,
                    // height: xs ? '50vh' : sm ? '50vh' : md ? '80vh' : '100vh'
                }}
            >
                <img src={`${process.env.PUBLIC_URL}/assets/img/new_family_bg.jpg`} width="100%" style={{ objectFit: 'cover', height: md ? '85vh' : minMd ? '70vh' : 'auto' }} />
                <div className='card'>
                    <div>
                        <h4>Accurate + Current + Comparative</h4>
                        <h1>Market Valuation Report</h1>
                        <p>Claim up to $1000 voucher marketing voucher (conditions apply)</p>
                        <TextField className='text-input' fullWidth placeholder='Enter Your Address' inputRef={ref}
                            InputProps={{
                                endAdornment: (
                                    md && <InputAdornment position="end">
                                        <Button variant='contained' size='small' className='custom-btn' onClick={() => address && history.push('/evaluation', address)}>
                                            Get Started Now - It's Free
                                        </Button>
                                    </InputAdornment>
                                    
                                )
                            }}
                        />
                        {
                            !md &&
                            <Button fullWidth variant='contained' size='small' className='custom-btn mt-1' onClick={() => address && history.push('/evaluation', address)}>
                                Get Started Now - It's Free
                            </Button>
                        }
                    </div>
                </div>
            </section>
            
            <section id="overview" className={`parallax-section pb-5 pt-3`}>
                <div className="container">
                    <div className="row">
                        <div className="wow fadeInUp col-sm-12 col-md-12 f-16" data-wow-delay="0.6s">
                            <h3 className='text-center mb-2' style={{ fontSize: md ? '26px' : '22px' }}><b>What's next for NZ's housing market? Will the market rise or fall?</b></h3>
                            <Grid container spacing={2} alignItems="center" className='mb-1'>
                                <Grid item xs={12} md={6}>
                                    <div className={`text-justify ${md ? 'f-16' : 'f-14'}`}>
                                        <h1 style={{ fontWeight: '900' }} className='text-left f-24 text-primary'>Are you thinking of selling your <span className=''>property?</span></h1>
                                        
                                        <p className='text-justify mt-1'>How will rising interest rates affect me?</p>  
                                        
                                        <p className='text-justify mt-1'>
                                            What is happening at the moment is very normal behaviour for the housing market. The housing market can be complex and influenced by numerous factors, and it can be difficult to predict how it will evolve in the future.
                                        </p>  
                                        
                                        <p className='text-justify mt-1'>
                                            Interest rates continue to rise and credit constraints remain in place. This could make it more challenging for homeowners to sell their property at their desired prices, which may result in more “cheeky" or lowball offers being made
                                        </p>                                          
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} container alignItems={'flex-start'} justifyContent="flex-start">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/img2.jpg`} style={{ width: '100%', height: '400px', objectFit: 'cover' }} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} flexDirection={md ? 'row' : 'column-reverse'}>
                                <Grid item xs={12} lg={6}>
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/bg2.jpg`} style={{ width: '100%', height: '300px', objectFit: 'cover', paddingBottom: 0  }} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <div className={`text-justify ${md ? 'f-16' : 'f-14'}`}>
                                        <p className='text-justify mt-1'>
                                            In such a situation, it's crucial for homeowners to seek advice from real estate professionals to understand the current market conditions, housing demand, and comparable sales in their area. Real estate professionals can provide valuable insights on the best time to sell, how to price the property, and strategies to make the property more attractive to potential buyers. They can also help homeowners navigate the complexities of the selling process, such as negotiating offers, preparing legal documents, and dealing with any issues that may arise during the sale.
                                        </p>
                                         
                                    </div>
                                </Grid>
                            </Grid>

                            <h3 className='text-center mb-2 mt-5' style={{ fontSize: md ? '22px' : '22px' }}>
                                <b>"Want to make sure your property stands out in today's competitive market? <br />
                                    Don't leave the sale of your property to chance - take advantage of our expertise and book your free appraisal today!"</b>
                                    <br /><br />
                                <Link to="/evaluation" className='custom-btn'>
                                    BOOK AN APPRAISAL
                                </Link>
                            </h3>

                        </div>
                    </div>
                </div>
            </section>

            <Divider />
            
            <section id="overview" className={`parallax-section pb-5 ${md ? 'pt-5' : 'pt-5'}`}>
                <div className="container">
                    <div className="row">
                        <div className="wow fadeInUp col-sm-12 col-md-12 f-16" data-wow-delay="0.6s">
                            <Grid container spacing={2} className='mb-2'>
                                <Grid item xs={12}>
                                    <b className='f-24 text-secondary'><span className='text-primary'>Set the Right Price from the Start</span></b>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p className={`text-justify ${md ? 'f-18' : 'f-16'}`}>
                                        <p className='text-justify mt-1'>
                                            Setting the right price for your property is crucial to achieving a successful sale. That's why it's important to have a realistic understanding of its value in the current market. At our property appraisal website, we offer a comprehensive appraisal service that considers all the factors that affect your property's value. Our real estate professionals have extensive knowledge of the local market conditions, comparable sales, and the unique features of your property.
                                        </p>                                         
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6} container alignItems={'flex-start'} justifyContent="flex-start">                            
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/bg1.jpeg`} style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={`text-center ${md ? 'f-16' : 'f-14'}`}>
                                        <p className='text-justify mt-1 mb-2'>
                                            Our appraisal process begins with a questionnaire that provides us with some initial information about your property. This is followed by an onsite visit by one of our real estate agents to gather more detailed information and assess the condition and features of your property. Using this information, we can prepare an accurate, current, and comparative appraisal of your property. 
                                            <br />
                                            <br />
                                            By working with our real estate professionals, you can be confident that you have a realistic understanding of your property's value. This can help you set the right price from the start, which can increase your chances of attracting potential buyers and achieving a successful sale.
                                        </p>  

                                        <Link to="/evaluation" className='custom-btn mt-1 mb-2'>
                                            FIND YOUR AGENT
                                        </Link>
                                    </div>
                                </Grid>


                                <Grid item xs={12} md={6} container alignItems={'flex-start'} justifyContent="flex-start">                            
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/bg3.jpg`} style={{ width: '100%', height: '350px', objectFit: 'cover' }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={`text-justify ${md ? 'f-16' : 'f-14'}`}>

                                        <h4 className='text-justify mt-1 text-secondary'>
                                            <b>
                                                New Housing Policy | Megatrends in Real Estate | Auckland Unitary Plan Updates 
                                            </b>
                                        </h4>                                         
                                        
                                        <p className='text-justify mt-1'>
                                            At Auckland House Prices, we understand the importance of staying up to date with the latest developments in the property market. We know that anyone selling or renting out properties needs to have a thorough understanding of the various forces affecting pricing and behaviours.
                                            <br />
                                            <br />
                                            Our agents have extensive knowledge of the local market conditions, including recent trends in property prices, buyer and seller behaviours, and the impact of government policies on the property market. This knowledge enables us to provide our clients with expert advice and guidance on how to navigate the complex property market and achieve their goals.
                                        </p>                                         
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <p className={`text-center ${md ? 'f-16' : 'f-14'} mt-0`}>
                                        <Link to="/contact" className='custom-btn mt-0'>
                                            GET IN TOUCH
                                        </Link>
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Divider />

            <section id="overview" className={`parallax-section pb-5 ${md ? 'pt-5' : 'pt-5'}`}>
                <div className="container">
                    <div className="row">
                        <div className="wow fadeInUp col-sm-12 col-md-12 f-16" data-wow-delay="0.6s">
                            <h3 className='text-center mb-0' style={{ fontSize: md ? '26px' : '22px', fontWeight: 800 }}>
                                New Housing Policy <span className='text-primary'>|</span> Megatrends in Real Estate <span className='text-primary'>|</span> Auckland Unitary Plan Updates
                            </h3>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <p className={`text-justify ${md ? 'f-16' : 'f-14'}`}>
                                        <p className='text-justify'>
                                        At Auckland House Prices, we have all the latest updates impacting the property market, as it's essential for anyone selling or renting 
                                        out properties to have a thorough understanding of the various forces affecting pricing and behaviours. All of our agents are fully up-to-date with the new changes that have already come into effect, as well as those coming in the near future.
                                        </p>  
                                        <a className='custom-btn mt-1'>
                                            Get in Touch
                                        </a>
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </section> */}


            <Divider />

            
            <section id="about" className={`parallax-section pb-6 pt-3`}>
                <div className="container">
                    <div className="">     
                        <h3 className='text-left mb-1' style={{ fontSize: md ? '26px' : '22px', fontWeight: 800 }}>
                            About Us
                        </h3>               
                        
                        <h4 className='text-justify mt-0 mb-1'>
                            Accurate <span className='text-primary' >+</span> Current <span className='text-primary' >+</span> Comparative 
                        </h4>     
                        <div className={`text-justify ${md ? 'f-16' : 'f-14'}`}>
                            <p className='text-justify mt-05'>
                                At Auckland House Prices we aim to set the best and correct price from 
                                the outset with our Comparative Analysis Report. If you are considering 
                                selling your home we also match the right vetted Licensed Real Estate 
                                Agent to achieve the set price range stated in our Comparative Market 
                                Analysis.
                            </p>  

                            <p className='text-justify mt-1'>
                                Our licensed and qualified agents will point buyers to the correct price 
                                range to achieve the premium result possible in the current market and 
                                to ensure the right buyers are enticed to view your property.
                            </p>  
                            
                            <p className='text-justify mt-1'>
                                All our agents are licenced under the Real Estate Agents Act 2008.
                            </p> 

                            <p className='text-justify mt-1'>
                                <b>Our Mission</b> <br />
                                Our mission is to give homeowners valuable information on how to fit their 
                                property into current market. We know what buyers are 
                                looking for, and we match them with the right properties, getting sellers 
                                the best possible premium result in the shortest time.
                            </p> 
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/assets/img/people.png`} style={{ width: '100%' }} />
                                        
                        <p className='text-center mt-3'>
                            <Link to="/evaluation" className='custom-btn text-center'>
                                START HERE
                            </Link>
                        </p>
                    </div>
                </div>
            </section>

            <Divider />

            <section id="overview" className={`parallax-section pb-5 ${'pt-7'}`}>
                <div className="container">
                    <div className="row">
                        <div className="wow fadeInUp col-sm-12 col-md-12 f-16" data-wow-delay="0.6s">
                            <h3 className='text-left mb-0' style={{ fontSize: md ? '26px' : '22px', fontWeight: 800 }}>
                                How This <span className='text-primary' >Free Service</span> Works For Auckland Homeowners
                            </h3>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className={`text-justify ${md ? 'f-16' : 'f-14'}`}>
                                        <p className='text-justify'>
                                            It's easy to get started with your accurate, current, and comparative market valuation report.
                                            <ul className=''>
                                                <li>Fill out the form</li>
                                                <li>Look forward to a phone call, confirming that we've received your request</li>
                                                <li>Check your email for your free, no-obligation report</li>
                                            </ul>
                                        </p>  
                                        <Link to="/evaluation" className='custom-btn mt-3'>
                                            START NOW
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </section>

            <Divider />

            <section id="faq" className={`parallax-section pb-7 ${'pt-7'}`}>
                <div className="container">
                    <div className="row">
                        <div className="wow fadeInUp col-sm-12 col-md-12 f-16" data-wow-delay="0.6s">
                            <h3 className='text-center mb-0' style={{ fontSize: md ? '26px' : '22px', fontWeight: 800 }}>
                                Frequently Asked Questions
                            </h3>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {
                                        faqs.map(({ title, desc }, key) => (
                                            <Accordion key={key} defaultExpanded={(key == 0)}>
                                                <AccordionSummary className='text-primary text-bold p-1 pl-3 pr-3 f-18'>
                                                    {title}
                                                </AccordionSummary>
                                                <AccordionDetails className='f-16 pl-3 pt-0 mt-0 pr-3 pb-3 text-justify'>
                                                    {desc}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={12} container alignItems={'center'} justifyContent='center'>
                                    <Link to="/contact" className='custom-btn mt-3'>
                                        Ask a Question
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </section>

            <Divider />

            <section id="market-update" className="parallax-section">
                <div className="container">
                    <h3 className='text-center mt-4 mb-0' style={{ fontSize: md ? '26px' : '22px', fontWeight: 800 }}>
                        Property Market Update
                    </h3>
                    <div className="">
                        <Grid container spacing={3} className="mt-2 mb-2">
                            <Grid item xs={12} lg={6}>
                                <iframe 
                                    src="https://datawrapper.dwcdn.net/UlwQ0/5/" 
                                    style={{ border: 0, width: '100%', height: '450px' }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                >    
                                </iframe>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <iframe 
                                    src="https://datawrapper.dwcdn.net/laeWB/3/" 
                                    style={{ border: 0, width: '100%', height: '510px' }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                >    
                                </iframe>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <iframe 
                                    src="https://datawrapper.dwcdn.net/lDNXJ/3/" 
                                    style={{ border: 0, width: '100%', height: '510px' }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                >    
                                </iframe>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <iframe 
                                    src="https://datawrapper.dwcdn.net/QThOu/3/"
                                    style={{ border: 0, width: '100%', height: '450px' }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                >    
                                </iframe>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </section>

            <Divider />

            <section id="blog" className="parallax-section mt-0 pt-10 mb-5">
                <div className="container">
                    <div className="">
                        <>
                            <div className="col-12">
                                <h3 className='text-center mb-0' style={{ fontSize: md ? '26px' : '22px', fontWeight: 800 }}>
                                    Blog
                                </h3>
                                <p className='text-center mt-1 mb-1'>Auckland Property News</p>
                                <p className='text-center mt-1 mb-2'>This is the place to find current information and news about the Auckland property market, including trends, issues, and regulatory changes. Our blog posts can also be found here, with our take on the news and articles that let you know what we've been up to.</p>
                            </div>
                            <Grid container spacing={3}>
                                {
                                    posts?.map((pt, key) => (
                                        <Grid key={key} item xs={12} md={6} lg={4} onClick={() => history.push('/blog/' + pt?.slug)} style={{ cursor: 'pointer' }}>
                                            <img src={pt?._embedded?.['wp:featuredmedia']?.['0']?.source_url || ''} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                                            <h4 className='mt-1 text-center'>{pt?.title?.rendered}</h4>
                                            {/* <p dangerouslySetInnerHTML={{ __html: pt?.excerpt?.rendered || '' }} /> */}
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </>
                    </div>
                </div>
            </section>

            <Divider />
            
            <section id="contact" className="parallax-section">
                <div className="container">
                    <div className="">
                        <>
                            <div className="col-12">
                                <h3 className='text-center mb-0' style={{ fontSize: md ? '26px' : '22px', fontWeight: 800 }}>
                                    Contact Us
                                </h3>
                                <p className='text-center mt-1 mb-2'>Get in touch with us any time. We'll get back to you as soon as possible, usually within 24 hours.</p>
                            </div>
                            <Formik
                                initialValues={{ fullName: '', subject: '', email: '', message: '' }}
                                onSubmit={contactForm} enableReinitialize
                            >
                                {
                                    ({ handleChange, isSubmitting, values  }) => (
                                        <Form>
                                            <div className="col-12 mb-2">
                                                <label>Full name *</label>
                                                <TextField value={values.fullName} fullWidth className='form-field' onChange={handleChange} required name="fullName" id="fullName" placeholder="Enter Full name" />
                                            </div>
                                            <div className="col-12 mb-2">
                                                <label>Subject *</label>
                                                <TextField value={values.subject} fullWidth className='form-field' onChange={handleChange} required name="subject" id="subject" placeholder="Enter your Subject" />
                                            </div>

                                            <div className="col-12 mb-2">
                                                <label>Email Address *</label>
                                                <TextField fullWidth  value={values.email} className='form-field' onChange={handleChange} required name="email" id="email" placeholder="Enter email address" type={"email"} />
                                            </div>
                                            
                                            <div className="col-12 mb-2">
                                                <label>Message *</label>
                                                <TextField multiline  value={values.message} minRows={3} fullWidth className='form-field' onChange={handleChange} required name="message" id="message" placeholder="Enter your message for us" />  
                                            </div>

                                            <div className="col-12 mb-0">
                                                <small style={{ color: sentMail?.status == 'success' ? 'green' : 'brown' }}>
                                                    {sentMail?.message}
                                                </small>
                                            </div>
                                            
                                            <div className="col-12" style={{ margin: 'auto' }}>
                                                <button className='custom-btn mt-3' type='submit' disabled={isSubmitting}>
                                                    { isSubmitting ? "Sending..." : 'Contact us' }
                                                </button>
                                            </div>
                                        </Form>
                                    )
                                }

                            </Formik>
                        </>
                    </div>
                </div>
            </section>
        </PageContainer>
    )
}

export default Home
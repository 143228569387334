import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch, useHistory, withRouter, Redirect, Link, use } from 'react-router-dom'
import Home from '../pages/Home';
import Page404 from '../pages/Page404';
import { homepage } from './appRoutes';
import BlogDetails from '../pages/BlogDetails';
import Evaluation from '../pages/Evaluation';

const ScrollToTop = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history.location.pathname]);
  
    return null;
};

const ResetScroll = withRouter(ScrollToTop);



const Routes = () => {

    return (
        <BrowserRouter>
            <ResetScroll />
            <Switch>
                <Route exact path={`${homepage}/home`}>
                    <Redirect to={`${homepage}/`} />
                </Route>

                <Route exact path={`${homepage}/`} component={Home} />
                <Route exact path={`${homepage}/about`} component={Home} />
                <Route exact path={`${homepage}/blog`} component={Home} />
                <Route exact path={`${homepage}/blog/:slug`} component={BlogDetails} />
                <Route exact path={`${homepage}/contact`} component={Home} />
                <Route exact path={`${homepage}/faq`} component={Home} />

                <Route exact path={`${homepage}/evaluation`} component={Evaluation} />

                <Route path="**">
                    <Page404 />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;

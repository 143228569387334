export const homepage = '';

const appRoutes = {
    root: `${homepage}`,
    services: `${homepage}/services`,
    about: `${homepage}/about`,
    contact: `${homepage}/contact`,
    auth: `${homepage}/sign-in`,
    page404: `${homepage}/404`,
    dashboard: {
        root: `${homepage}/dashboard`
    },
    dashboard: {
        root: `${homepage}/analytics`
    },
    audit: {
        root: `${homepage}/audit-report`
    },
    advisory: {
        root: `${homepage}/security-advisory`,
        new: `${homepage}/security-advisory/new`
    },
    reporting: {
        root: `${homepage}/reporting`,
        incident: {
            root: `${homepage}/reporting/security-incident`,
            new: `${homepage}/reporting/security-incident/new`
        },
        threat: {
            root: `${homepage}/reporting/threat`,
            new: `${homepage}/reporting/threat/new`
        },
    },
    userMgt: {
        root: `${homepage}/user-management/users`,
        roles: {
            root: `${homepage}/user-management/roles`,
            new: `${homepage}/user-management/roles/new`
        },
        users: {
            root: `${homepage}/user-management/users`,
            new: `${homepage}/user-management/users/new`
        },
        internalUsers: {
            root: `${homepage}/user-management/internal-users`,
            new: `${homepage}/user-management/internal-users/new`
        },
        privileges: {
            root: `${homepage}/user-management/privileges`,
            new: `${homepage}/user-management/privileges/new`
        },
        institution: {
            root: `${homepage}/user-management/institutions`,
            new: `${homepage}/user-management/institutions/new`
        },
    }
}

export default appRoutes;
import { Menu } from '@mui/icons-material'
import { Container, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { regLink } from '../util'
import Loader from './common/Loader'
import { CheckCircle, Facebook, Hotel, Instagram, LinkedIn, Star } from '@mui/icons-material'

const PageContainer = ({ children, processing = false, pageTitle = '' }) => {
    const sm = useMediaQuery({ query: '(max-width: 400px)' })
    const md = useMediaQuery({ query: '(max-width: 850px)' })

    const [ showMenu, setShowMenu ] = useState(false)
    const links = [
        { label: 'Home', link: '/' },
        { label: 'About', link: '/about' },
        { label: 'Blog', link: '/blog' },
        { label: 'FAQs', link: '/faq' },
        { label: 'Contact', link: '/contact' }
    ]

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            { pageTitle && <Helmet><title>{pageTitle} | Auckland Home Prices</title></Helmet> }
            {processing && <Loader />}
            
            <div>
                <div className="navbar navbar-fixed-top custom-navbar" role="navigation"
                    style={{
                        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
                    }}
                >
                    <Container>
                        <Grid container alignItems={'center'}>
                            <Grid item xs={9} sm={6} md={4} lg={3}>
                                <a href='/' className="brand">
                                    <img src={process.env.PUBLIC_URL + "/logo.png"} style={{ maxWidth: '100%', maxHeight: '50px', objectFit: 'contain' }} />
                                </a>
                            </Grid>
                            <Grid item xs={3} sm={6} md={8} lg={9} container justifyContent={'flex-end'} alignItems="center">
                                {
                                    md ?
                                    <IconButton size='large' onClick={() =>  setShowMenu(!showMenu)}>
                                        <Menu style={{ fontSize: '24px' }} />
                                    </IconButton>
                                    :
                                    <ul className="nav navbar-nav navbar-right">
                                        {
                                            links.map((link, key) => (
                                                <li key={key}><Link to={link.link} className="smoothScroll">{link.label}</Link></li>
                                            ))
                                        }
                                    </ul>
                                }
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                {children}
            </div>
            <Divider />
            <footer className='pt-2 pb-2'>
                <div className="container">
                    <div className="">
                        <div className="col-12">
                            <p className="wow fadeInUp" data-wow-delay="0.6s">
                                Copyright &copy; {new Date().getFullYear()}. All rights reserved | <a className='text-primary' target={"_blank"} href='/Website_privacy_policy.pdf'>Terms and Conditions</a> | <a className='text-primary' href='/'>Auckland House Prices</a>
                            </p>
                        </div>
                        <div className="col-12">
                            <div className="footer-social">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/aucklandhouseprices">
                                    <Facebook />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/aucklandhouseprices/">
                                    <Instagram />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/akl-house-prices">
                                    <LinkedIn />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <a onClick={() => window.scrollTo(0, 0)} className="go-top"><i className="fa fa-angle-up"></i></a>


            <Drawer open={showMenu} onClose={() => setShowMenu(false)} anchor='right' className='mobile-menu'>
                <h3 style={{ fontSize: '24px' }}><b>Menu</b></h3>
                <Divider />
                <ul className='nav' style={{ flexDirection: 'column', minWidth: '50vw'  }}>
                    {
                        links.map((link, key) => (
                            <li key={key}><Link to={link.link} className="smoothScroll">{link.label}</Link></li>
                        ))
                    }
                </ul>
            </Drawer>
        </div>
    )
}

export default PageContainer
import Routes from './routes'
import Theme from './styles/theme';
import { useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AOS from "aos";
import { SnackbarProvider } from 'notistack';

const App = () => {
    const stackRef = useRef(null);

    const onClose = (key) => () => {
        stackRef.current.closeSnackbar(key);
    };
    
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-sine',
            delay: 100,
            once: false
        });
        // AOS.refresh();
    }, []);
    

    return (        
        <SnackbarProvider
            ref={stackRef}
            dense
            maxSnack={1}
            preventDuplicate
            autoHideDuration={3000}
            variant="success" // Set default variant
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            // With close as default
            action={(key) => (
                <IconButton size="small" color='inherit' onClick={onClose(key)} sx={{ p: 0.5 }}>
                    <Close />
                </IconButton>
            )}
        
        >
            <Theme>
                <Routes />
            </Theme>
        </SnackbarProvider>
    );
}

export default App;
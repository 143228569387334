import { LinearProgress } from '@mui/material'
import React from 'react'
import Logo from '../common/Logo'

const Loader = () => {
    return (
        <div className='loader'>
            <Logo width={300} height={'auto'} disabledLink />
            <LinearProgress />
        </div>
    )
}

export default Loader
